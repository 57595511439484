
  body {
      margin: 0;
      padding: 0;
      height: 100vh;
    }

    .footer {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      
      padding: 1px;
      text-align: center;
    }
    
	
	
    .fade-in-out {
      animation: fadeInOut 4s infinite;
    }

    @keyframes fadeInOut {
      0% { opacity: 0; }
      50% { opacity: 1; }
      100% { opacity: 0; }
    }
 
	.containerbox {
      display: flex;
      align-items: center;
    }
	
	.image2 {
		width: 50px;
		height: auto;
	
	}
    .image {
      width: 300px; /* Adjust the width of the image as needed */
      height: auto; /* Adjust the height of the image as needed */
       /* Adjust the spacing between the image and text as needed */
    }
  
     .container {
		
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 80vh;
	}
    .checkbox-input {
      background:  #e6e9e9  repeat-y left center;
      padding-left: 85px;
    }

    .verifyCheckbox {
      width: 30px;
      height: 30px;
    }

    .cap-table {
      display: flex;
      align-items: center;
      border-radius: 3px;
      padding: 10px;
      border: #E0E0E0 1px solid;
      background-position: right 10px center;
      background-repeat: no-repeat;
      background-color: #f4f6f6;
      width: 310px;
      height: 70px;
    }

    .cap-table label {
      margin-left: 5px;
      font-family: Geneva, sans-serif, Tahoma;
      font-size: 14px;
      /* Adjust the margin as needed */
    }
  
